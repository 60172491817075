body {
  height: 100vh;
  animation: colorAnimate 20s linear infinite;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Alata", sans-serif !important;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff30;
}

@keyframes colorAnimate {
  0%,
  100% {
    background-color: rgb(255, 88, 91);
  }

  30% {
    background-color: rgb(129, 101, 255);
  }

  70% {
    background-color: rgb(223, 255, 97);
  }
}
